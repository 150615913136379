import React, { memo } from 'react'
import PropTypes from 'prop-types'

import DivideLineStyled from './styledComponent'

const DivideLine = (props) => {
  return <DivideLineStyled className={props.className} />
}

DivideLine.propTypes = {
  className: PropTypes.string
}

export default memo(DivideLine)
